const AdminSideMenu = [
  {
    title: 'サンプル管理表',
    icon: 'el-icon-reading',
    path: '/sample-management',
    routerName: 'routes.sampleManagement',
    isHasChild: false,
  },
  {
    title: '量産管理表',
    icon: 'el-icon-notebook-2',
    path: '/mass-management',
    routerName: 'routes.massProduction',
    isHasChild: false,
  },
  {
    title: 'マスタ登録',
    icon: 'el-icon-coin',
    path: '/master-management',
    routerName: 'routes.masterDataManagement',
    isHasChild: false,
  },
  {
    title: '会社情報の登録',
    icon: 'el-icon-school',
    path: '/company-management/create',
    routerName: 'routes.companyManagement.create',
    isHasChild: false,
  },
]

const CompanySideMenu = [
  {
    title: 'サンプル管理表',
    icon: 'el-icon-notebook-1',
    path: '/sample-management',
    routerName: 'routes.sampleManagement',
    isHasChild: false,
  },
  {
    title: '量産管理表',
    icon: 'el-icon-notebook-2',
    path: '/mass-management',
    routerName: 'routes.massProduction',
    isHasChild: false,
  }
]

export {
  AdminSideMenu,
  CompanySideMenu
}

