<template src="./Sidebar.html"></template>
<style scoped lang="scss" src="./Sidebar.scss"></style>

<script>
import { AdminSideMenu, CompanySideMenu } from './side-menu-item'
import { COOKIES, Permission } from '@/constants'
import VueCookies from "vue-cookies";

export default {
  name: 'Sidebar',
  props: {
    isShow: {
      require: true,
      default: true,
      type: Boolean
    }
  },
  data() {
    return {
      userRole: Number(VueCookies.get(COOKIES.role)),
      items: []
    }
  },
  computed: {
    sideMenuItems() {
      if (this.userRole === Permission.Admin) return AdminSideMenu
      if (this.userRole === Permission.User) return CompanySideMenu

      return []
    },

    isCollapse() {
      return !this.isShow
    },

    onRoutes() {
      const routeName = this.$route.name || ''

      const pathOfSample = '/sample-management'
      const pathOfMass = '/mass-management'
      const pathOfMaster = '/master-management'
      const pathOfCompany = '/company-management/create'

      if (routeName === 'routes.sampleManagement') return pathOfSample
      if (routeName === 'routes.sampleManagement.create') return pathOfSample
      if (routeName === 'routes.sampleManagement.edit') return pathOfSample

      if (routeName === 'routes.massManagement') return pathOfMass
      if (routeName === 'routes.massManagement.create') return pathOfMass
      if (routeName === 'routes.massManagement.edit') return pathOfMass

      if (routeName === 'routes.masterManagement') return pathOfMaster
      if (routeName === 'routes.masterManagement.create') return pathOfMaster
      if (routeName === 'routes.masterManagement.edit') return pathOfMaster

      if (routeName === 'routes.companyManagement.create') return pathOfCompany

      return routeName;
    }
  },
  methods: {
    onClickToLogo() {
      this.$router.push({ name: 'routes.home' })
    }
  }
}
</script>
